<template>
    <v-menu v-model="menu"
            :close-on-content-click="closeOnContentClick"
            :nudge-right="nudgeRight"
            :transition="transition"
            :offset-y="offsetY"
            :max-width="maxWidth"
            :min-width="minWidth"
    >
        <template v-slot:activator="{ on }">
            <base-text-field v-on="on"
                             :outlined="outlined"
                             v-model="v"
                             :type="type"
                             :label="label"
                             :placeholder="placeholder"
                             :persistent-placeholder="persistentPlaceholder"
                             :hint="hint"
                             :hide-details="hideDetails"
                             :persistent-hint="persistentHint"
                             :clearable="clearable"
                             :counter="counter"
                             :maxlength="maxlength"
                             :readonly="readonly"
                             :disabled="disabled"
                             :loading="loading"
                             :full-width="fullWidth"
                             :rules="rules"
                             :prepend-icon="prependIcon"
                             :prepend-inner-icon="prependInnerIcon"
                             :append-icon="appendIcon"
                             @click:appendClick="$emit('appendClick', $event)"
            ></base-text-field>
        </template>
        <v-time-picker v-if="isTime"
                       v-model="v"
                       full-width
                       scrollable
                       format="24hr"
                       use-seconds
                       :disabled="disabled"
                       :allowed-hours="allowedHours"
                       :allowed-minutes="allowedMinutes"
                       :allowed-seconds="allowedSeconds"
                       @input="$emit('input', $event)"
        ></v-time-picker>
        <v-date-picker v-else
                       v-model="v"
                       full-width
                       scrollable
                       :locale="locale"
                       :disabled="disabled"
                       :allowed-dates="allowedDates"
                       @input="$emit('input', $event)"
        ></v-date-picker>
    </v-menu>
</template>

<script>
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';

    export default {
        name: 'BaseDataField',
        components: {BaseTextField},
        props: {
            //Menu
            closeOnContentClick: {type: Boolean, default: false},
            nudgeRight: {type: Number, default: 40},
            transition: {type: String, default: 'scale-transition'},
            offsetY: {type: Boolean, default: true},
            maxWidth: {type: String, default: '300px'},
            minWidth: {type: String, default: '300px'},
            //TextField
            outlined: {type: Boolean, default: true},
            value: [String, Number],
            type: String,
            label: String,
            placeholder: String,
            persistentPlaceholder: {type: Boolean, default: true},
            hint: String,
            hideDetails: {type: Boolean, default: undefined},
            persistentHint: {type: Boolean, default: true},
            clearable: Boolean,
            counter: {type: Boolean, default: true},
            maxlength: [Number, String],
            readonly: {type: Boolean, default: true},
            disabled: Boolean,
            loading: Boolean,
            fullWidth: Boolean,
            rules: Array,
            prependIcon: String,
            prependInnerIcon: String,
            appendIcon: String,
            //DateField
            isTime: Boolean,
            locale: {type: String, default: 'IT-it'},
            allowedDates: Function,
            allowedHours: Function,
            allowedMinutes: Function,
            allowedSeconds: Function
        },
        watch: {
            value: function () {
                this.v = this.value;
            }
        },
        data: () => ({
            v: undefined,
            menu: false
        }),
        created() {
            this.v = this.value;
        }
    }
</script>

<style scoped>

</style>
